import { Buzzartgen } from './buzz';

'use strict';

const $ = (sel, context=document) => context.querySelector(sel);
const $$ = (sel, context=document) => Array.from(context.querySelector(sel));

// $('.nojs').style.display = 'none';

function getPerson(callback) {
  return fetch('//api.randomuser.me/')
  .then (response => response.json());
}

function titleCase(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

function formatName(name) {
  return titleCase(name.first) + ' ' + titleCase(name.last);
}

function buzz() {
  return getPerson()
  .then (person => {
    person = person.results[0];
    $('#artistName').innerHTML = formatName(person.name);
    $('#face').src = person.picture.large;
    Buzzartgen.buzz('target');
  })
  .catch (error => {
    console.error(error);
    alert(`Sorry, this isn't working!`);
  });
}

window.onload = () => { // onLoad
  $('#moreQuotes').addEventListener('click', buzz);
  buzz();
  $('#buzzart').style.backgroundPosition = 'left top';
  // $('.nojs-hidden').classList.remove('nojs-hidden');
};
