'use strict';
/**
 *  Author: Luke Hudson
 *  Copyright 2009: Laura Hudson et al for original buzzartword generator.
 */


export const Buzzartgen = {
    words: [
        // COLUMN 1:
        "categorical provocative contemporary thematic performative intertextual heuristic algorithmical contextual coded compositional corporeal subversive unconditional ambiguous destratifying liminal numinous evocative analeptic subjective transparent simulacral temporal hierarchical reified commodified alienated tactical inscripted",
        // COLUMN 2:
        "aesthetic conceptual paradoxical generative haptic social expository iconographic signifying reflexive proliferating deleterious pivotal hypothetical polysyllabic polysemous rhizomatic authenticating cyclic dystopic existential chaotic porous fetishistic inscribed dominant porous ritualised nuanced teleological",
        // COLUMN 3:
        "metaphor metonymy challenge amelioration intensification transcendence configuration praxis investigation mediation valency hegemony space permeability ephemerability dichotomy continuum materiality discourse segmentarity multiplicity rupture representation habitus",
        // COLUMN 3 ALTERNATE:  words which require an article before the phrase to work best.
        "paradigm palimpsest surface boundary trajectory project",
        // ARTICLES
        "the a"
    ],

    // Potential lead-in phrases
    intros: [
        "Let us not forget the impact of",
        "At the basest level, my work is mostly concerned with",
        "One cannot simply ignore",
        "I see current radical art movements discovering new instances of",
        "This is how the modern artist defines his or her self in the context of",
        "Now we're seeing the death of",
        "I'm excited by the movement towards more",
        "because, until now, really all Western thought has been about",
        "this is contrasted with the more eastern concept of",
        "The new generation will increasingly challenge the notion of",
        "which leads us naturally to a closer inspection of",
        "It is nothing more than fear that limits us to",
        "With truly enlightened analysis, her work is revealed as",
        "And by that I mean",
        "Which I'm sure you'll understand as",
        "You musn't mistake that for",
        "Surely you recognize that as merely",
        "I suppose we're talking about",
        "In a recent interview I mentioned",
        "All of Banksy's work is about"
    ],

    init: function() {
        if (this.hasDoneInit) {
            return;
        }

        // Split text into arrays, like perl's qw{}
        var words = Buzzartgen.words;
        for (var i in words) {
            if (words.hasOwnProperty(i)) {
                words[i] = words[i].split(' ');
            }
        }
        this.hasDoneInit = true;
    },

    /* Pick a random array element */
    rndElt: function(inArray) {
        var x = Math.floor(Math.random() * inArray.length);
        return inArray[x];
    },


    /* Generate a buzzphrase */
    buzz: function(targetID, showintro) {
        Buzzartgen.init();
        if (targetID === undefined) {
            targetID = 'target';
        }
        if (showintro === undefined) {
            showintro = true;
        }

        var rndElt    = Buzzartgen.rndElt;
        var words     = Buzzartgen.words;
        var intros    = Buzzartgen.intros;
        var s         = [rndElt(words[0]), rndElt(words[1])].join(' ');
        var spanstart = '<span id="phrase">';
        var spanstop  = '</span>';
        var intro     = spanstart;

        if (Math.random() > 0.5) {
            if (showintro) {
                intro = rndElt(intros) + ' ' + 'the';
                intro = '<span class="first">' +
                    intro[0].toUpperCase() + '</span>' +
                    spanstart +
                    intro.substr(1);
            }
            s = [intro, s, rndElt(words[3]), spanstop].join(' ');
        } else {
            if (showintro) {
                intro = rndElt(intros);
                intro = '<span class="first">' +
                    intro[0].toUpperCase() + '</span>' +
                    spanstart + intro.substr(1) ;
            }
            s = [intro, s, rndElt(words[2]), spanstop].join(' ');
        }
        document.getElementById(targetID).innerHTML = s;
    }
};
